<template>
	<v-sheet class="project" style="height: calc(100vh - 88px)">
		<template v-if="contentLoaded">
			<v-layout class="align-center light-blue-bg px-4 py-2">
				<v-flex md12>
					<div class="d-flex pb-3">
						<p class="my-auto font-level-3-bold">
							<span class="fw-500">
								<b>
									<ShowValue :object="project" object-key="name" label="project name"></ShowValue>
								</b>
								<v-chip v-if="project?.barcode" color="cyan" class="ml-2" label text-color="white">{{
									project?.barcode
								}}</v-chip>
								<template v-if="project.status == 1">
									<v-chip class="ml-2" color="orange" text-color="white" label> Draft </v-chip>
								</template>
								<template v-if="project.status == 2">
									<v-chip class="ml-2" color="blue" text-color="white" label> In-Progress </v-chip>
								</template>
								<template v-if="project.status == 3">
									<v-chip class="ml-2" color="green" text-color="white" label> Completed </v-chip>
								</template>
							</span>
						</p>
						<v-spacer></v-spacer>
						<div v-if="project.status === 1">
							<v-btn
								depressed
								tile
								class="blue darken-4 mr-2 my-auto"
								color="white--text "
								v-on:click="doAction('edit')"
							>
								<v-icon class="mr-1">mdi-pencil</v-icon>
								Edit
							</v-btn>
						</div>
						<div>
							<v-btn
								v-if="getPermission('project:delete')"
								depressed
								tile
								class="blue darken-4 mr-2 my-auto"
								color="white--text "
								v-on:click="doAction('delete')"
							>
								<v-icon class="mr-1">mdi-delete</v-icon>
								Delete
							</v-btn>
						</div>

						<div>
							<template v-if="getPermission('project:update')">
								<v-btn
									v-if="project.status == 1"
									depressed
									tile
									class="blue darken-4 mr-2 my-auto"
									color="white--text "
									v-on:click="startProjectDialog = true"
								>
									Start
								</v-btn>
							</template>
						</div>
						<div>
							<template v-if="getPermission('project:update')">
								<v-btn
									v-if="project.status == 2"
									depressed
									tile
									class="blue darken-4 mr-2 my-auto"
									color="white--text "
									v-on:click="completeProjectDialog = true"
								>
									Complete
								</v-btn>
							</template>
						</div>
						<div>
							<v-btn :disabled="pageLoading" depressed tile v-on:click="goBack()"> Cancel </v-btn>
						</div>
					</div>
					<div class="d-flex">
						<div class="d-flex pb-3 w-33">
							<div>
								<div>
									<span class="fw-600">Customer # : </span>
									<span class="fw-500">
										<v-chip v-if="customer?.barcode" color="cyan" class="ml-2" label text-color="white">{{
											customer.barcode
										}}</v-chip>
									</span>
								</div>
								<div class="my-3">
									<span class="fw-600">Company Name : </span>
									<span class="fw-500">
										<ShowValue :object="customer" object-key="company_name" label="Company Name"> </ShowValue>
									</span>
								</div>
								<div>
									<span class="fw-600">Display Name : </span>
									<span class="fw-500">
										<ShowValue :object="customer" object-key="display_name" label="Display Name"> </ShowValue>
									</span>
								</div>
								<div class="mt-3">
									<span class="fw-600">Email : </span>
									<span class="fw-500">
										<ShowValue class="ml-2" :object="customer" object-key="email" label="Email"> </ShowValue>
									</span>
								</div>
							</div>
						</div>
						<div class="pb-3 w-33">
							<div class="my-3">
								<span class="fw-600">Phone : </span>
								<span class="fw-500">
									<ShowValue class="ml-2" :object="customer" object-key="phone" label="Phone"> </ShowValue>
								</span>
							</div>
							<div>
								<span class="fw-600">Reference : </span>
								<span class="fw-500">
									<ShowValue class="ml-2" :object="project" object-key="reference_no" label="Reference">
									</ShowValue>
								</span>
							</div>
							<div class="my-3">
								<span class="fw-600">Start Date : </span>
								<span class="fw-500 no-wrap">
									{{ formatDate(project.start_date) }}
								</span>
							</div>
							<div>
								<span class="fw-600">End Date : </span>
								<span class="fw-500 no-wrap">
									{{ formatDate(project.end_date) }}
								</span>
							</div>
						</div>
						<div class="pb-3 w-33">
							<div>
								<span class="fw-600">Project Value : </span>
								<span class="fw-500">
									<ShowPrice class="ml-2" :object="project" object-key="project_value" label="Project Value">
									</ShowPrice>
								</span>
							</div>
							<div class="my-3">
								<span class="fw-600">Project Budget : </span>
								<span class="fw-500">
									<ShowPrice class="ml-2" :object="project" object-key="cost" label="cost"> </ShowPrice>
								</span>
							</div>
							<div>
								<span class="fw-600">Total Deliverable : </span>
								<span class="fw-500"> 10/20 </span>
							</div>
							<div class="my-3">
								<span class="fw-600">Total Delivered : </span>
								<span class="fw-500"> 10/50 </span>
							</div>
							<!-- <div class="my-3">
								<span class="fw-600">Project Cost: </span>
								<span class="fw-500">
									<ShowValue class="ml-2" :object="project" object-key="cost" label="Project Cost">
									</ShowValue>
								</span>
							</div> -->
						</div>
					</div>
					<!-- <div class="d-flex pb-3">
						<div>
							<span class="fw-600">Email : </span>
							<span class="fw-500">
								<ShowValue class="ml-2" :object="customer" object-key="email" label="Email">
								</ShowValue>
							</span>
						</div>
						<div>
							<span class="fw-600">Phone : </span>
							<span class="fw-500">
								<ShowValue class="ml-2" :object="customer" object-key="phone" label="Phone"> </ShowValue>
							</span>
						</div>
					<div><span class="fw-600">Start Date : </span>
							<span class="fw-500 no-wrap">
								{{ formatDate(project.start_date) }}
							</span></div>
						<div class="w-33">
							
						</div>
					</div>
					<div class="d-flex pb-3">
						<div class="w-33">
							<span class="fw-600">End Date : </span>
							<span class="fw-500 no-wrap">
								{{ formatDate(project.end_date) }}
							</span>
						</div>
						<div class="w-33">
							<span class="fw-600">Reference : </span>
							<span class="fw-500">
								<ShowValue class="ml-2" :object="project" object-key="reference_no" label="Reference">
								</ShowValue>
							</span>
						</div>
						<div class="w-33">
							<span class="fw-600">Project Value : </span>
							<span class="fw-500">
								<ShowValue class="ml-2" :object="project" object-key="project_value" label="Project Value">
								</ShowValue>
							</span>
						</div>
					</div> -->
					<!-- {{ project }} -->
					<!-- <div class="d-flex pb-3">
						<div class="w-33">
							<span class="fw-600">Project Budget : </span>
							<span class="fw-500">
								<ShowValue class="ml-2" :object="project" object-key="project_budget"
									label="Project Budget">
								</ShowValue>
							</span>
						</div>
					</div> -->
				</v-flex>
			</v-layout>
			<div>
				<v-tabs
					v-model="projectTab"
					background-color="transparent"
					color="blue"
					class="custom-tab-transparent"
					active-class="blue darken-4 text-white"
					hide-slider
				>
					<v-tab href="#detail">
						<v-icon small left>mdi-format-list-bulleted-square</v-icon> Overview</v-tab
					>
					<v-tab href="#task"> <v-icon small left>mdi-calendar-check</v-icon> Tasks</v-tab>
					<v-tab href="#budget"> <v-icon small left>mdi-currency-usd</v-icon> Budget</v-tab>
					<v-tab v-if="getPermission('transaction:view')" href="#transaction">
						<v-icon small left>mdi-share-outline</v-icon> Transaction
					</v-tab>
					<v-tab v-if="false" href="#asset"> <v-icon small left>mdi-share-outline</v-icon> Asset</v-tab>
					<v-tab v-if="false" href="#asset-stock">
						<v-icon small left>mdi-share-outline</v-icon> Asset Stock</v-tab
					>
					<v-tab href="#milestone"> <v-icon small left>mdi-flag</v-icon> Milestone</v-tab>
					<v-tab href="#proforma"> <v-icon small left>mdi-receipt</v-icon>Milestone Proforma</v-tab>

					<v-tab href="#invoice">
						<inline-svg
							:fill="projectTab === 'invoice' ? 'white' : 'Gray'"
							:src="$assetURL('media/custom-svg/receipt_long.svg')"
							height="15"
							width="15"
							class="mr-2"
						/>
						Milestone Invoice</v-tab
					>
					<v-tab href="#payment">
						<inline-svg
							:fill="projectTab === 'payment' ? 'white' : 'Gray'"
							:src="$assetURL('media/custom-svg/payment.svg')"
							height="15"
							width="15"
							class="mr-2"
						/>
						Milestone Payment</v-tab
					>
					<v-tab href="#quotation"
						><inline-svg
							:fill="projectTab === 'quotation' ? 'white' : 'Gray'"
							:src="$assetURL('media/custom-svg/quotation1.svg')"
							height="15"
							width="15"
							class="mr-2"
						/>Quotation</v-tab
					>

					<v-tab href="#team-member">
						<v-icon small left>mdi-account-group-outline</v-icon> Team Member</v-tab
					>

					<v-tab href="#line_items">
						<v-icon small left>mdi-account-group-outline</v-icon> Deliverable Line Items</v-tab
					>
					<v-tab href="#file"> <v-icon small left>mdi-file-document-multiple</v-icon>Files</v-tab>
					<v-tab href="#event"> <v-icon small left>mdi-history</v-icon>Logs</v-tab>
				</v-tabs>

				<v-tabs-items v-model="projectTab">
					<v-tab-item value="detail">
						<v-row>
							<v-col md="6">
								<div class="mx-4">
									<div class="overflow-y" style="max-height: calc(100vh - 250px)">
										<v-layout class="border-bottom-light-grey min-height-57px">
											<v-flex class="font-level-3-bold my-auto">
												<!-- <span class="detail-svg-icon mr-2"> -->
												<!--begin::Svg Icon-->
												<inline-svg
													style="width: 20px; height: 20px"
													fill="#006fc8"
													:src="$assetURL('media/custom-svg/overview.svg')"
												/>
												<!--end ::Svg Icon-->
												<!-- </span> -->
												Project Details
											</v-flex>
										</v-layout>
										<table width="100%" class="detail-table scattered-table">
											<tr style="vertical-align: top">
												<th width="25%">Description</th>
												<td width="75%">
													<ShowValue
														:object="project"
														object-key="description"
														label="Project Description"
													></ShowValue>
												</td>
											</tr>
											<tr>
												<th width="25%">Specification</th>
												<td width="25%">
													<ShowValue :object="project" object-key="specifications" label="Specification">
													</ShowValue>
												</td>
											</tr>
											<tr>
												<td colspan="4">
													<v-layout class="border-bottom-light-grey min-height-57px">
														<v-flex class="font-level-3-bold my-auto">
															<span class="detail-svg-icon mr-2">
																<!--begin::Svg Icon-->
																<inline-svg fill="#006fc8" :src="$assetURL('media/custom-svg/bank.svg')" />
																<!--end::Svg Icon-->
															</span>
															Banker Guarantee
														</v-flex>
													</v-layout>
												</td>
											</tr>
										</table>
										<table width="100%" class="detail-table scattered-table">
											<tr>
												<th width="25%">Bank Name</th>
												<td width="25%">
													<ShowValue :object="project" object-key="bankers_guarantee_name" label="Bank Amount">
													</ShowValue>
												</td>
												<th width="25%">Amount</th>
												<td width="25%">
													<ShowValue
														:object="project"
														object-key="banker_guarantee_amount'"
														label="Banker Guarantee Amount"
													>
													</ShowValue>
												</td>
											</tr>
											<tr>
												<th width="25%">Remark</th>
												<td width="25%">
													<ShowTextAreaValue
														:object="project"
														object-key="bankers_guarantee_remark"
														label="Remark"
													>
													</ShowTextAreaValue>
												</td>
											</tr>
										</table>
									</div>
								</div>
							</v-col>
							<v-col md="6">
								<div class="font-level-4-bold">Task:</div>
								<div id="chart">
									<apexchart type="pie" width="380" :options="chartOptions" :series="series"></apexchart>
								</div>
								<!-- <div class="font-level-4-bold mt-6">Budget: </div>
								<div id="chart">
									<apexchart type="bar" height="430" :options="barChartOptions" :series="groupSeries">
									</apexchart>
								</div> -->
							</v-col>
						</v-row>
					</v-tab-item>
					<v-tab-item value="transaction">
						<template v-if="projectTab == 'transaction'">
							<TransactionListing
								relation-type="project"
								:relation-type-id="project.id"
							></TransactionListing>
							<TransactionListing
								relation-type="project"
								:relation-type-id="project.id"
							></TransactionListing>
						</template>
					</v-tab-item>
					<v-tab-item value="asset">
						<CheckoutListing
							v-if="project && project.id && projectTab == 'asset'"
							class="mx-4"
							relation-type="project"
							:relation-type-id="project.id"
							type="asset"
							title="Assets"
						>
						</CheckoutListing>
					</v-tab-item>
					<v-tab-item value="members">
						<v-simple-table class="bt-table mt-3">
							<thead>
								<tr>
									<th>
										<v-layout>
											<v-flex class="text-left"> Image </v-flex>
										</v-layout>
									</th>
									<th>
										<v-layout>
											<v-flex class="text-left"> Incharge Officer # </v-flex>
										</v-layout>
									</th>
									<th>
										<v-layout>
											<v-flex class="text-left"> Email </v-flex>
										</v-layout>
									</th>
									<th>
										<v-layout>
											<v-flex class="text-left"> Display Name </v-flex>
										</v-layout>
									</th>
									<th>
										<v-layout>
											<v-flex class="text-left"> Identification Number </v-flex>
										</v-layout>
									</th>
									<th>
										<v-layout>
											<v-flex class="text-left"> Phone Number </v-flex>
										</v-layout>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(td, index) in project.members" :key="index">
									<td>
										<ImageTemplate circle style="max-width: 50px" :src="td.member.image"> </ImageTemplate>
									</td>
									<td>
										<Chip :text="td.member.barcode" color="cyan"></Chip>
									</td>
									<td>
										<ShowValue :object="td" object-key="member.email" label="Email"></ShowValue>
									</td>
									<td>
										<ShowValue :object="td" object-key="member.display_name" label="Display Name">
										</ShowValue>
									</td>
									<td>
										<ShowValue :object="td" object-key="member.id_number" label="Identification Number">
										</ShowValue>
									</td>
									<td>
										<ShowValue :object="td" object-key="member.phone_number" label="Phone Number">
										</ShowValue>
									</td>
								</tr>
							</tbody>
						</v-simple-table>
					</v-tab-item>
					<v-tab-item value="task">
						<TasksListing v-if="project" :project="project" class="mx-4"> </TasksListing>
					</v-tab-item>
					<v-tab-item value="budget">
						<BudgetListing v-if="project" :project="project" class="mx-4"> </BudgetListing>
					</v-tab-item>
					<v-tab-item value="milestone">
						<MilestoneListing v-if="project" :project="project" class="mx-4"> </MilestoneListing>
					</v-tab-item>
					<v-tab-item value="quotation">
						<QuotationListing :project="project" class="mx-4"> </QuotationListing>
					</v-tab-item>
					<v-tab-item value="proforma">
						<ProformaListing v-if="project" :project="project" class="mx-4"> </ProformaListing>
					</v-tab-item>
					<v-tab-item value="invoice">
						<InvoiceListing class="mx-4" v-if="project && projectTab === 'invoice'" :project="project">
						</InvoiceListing>
					</v-tab-item>
					<v-tab-item value="payment">
						<PaymentListing class="mx-4" :project="project" v-if="project && projectTab === 'payment'">
						</PaymentListing>
					</v-tab-item>
					<v-tab-item value="line_items">
						<ProjectLineItems class="mx-4"> </ProjectLineItems>
					</v-tab-item>
					<v-tab-item value="team-member">
						<TeamMemberListing
							class="mx-4"
							:project="project"
							v-if="project && projectTab === 'team-member'"
						>
						</TeamMemberListing>
					</v-tab-item>
					<v-tab-item value="asset-stock">
						<CheckoutListing
							v-if="project && project.id && projectTab == 'asset-stock'"
							class="mx-4"
							relation-type="project"
							:relation-type-id="project.id"
							type="asset-stock"
							title="Asset Stocks"
						>
						</CheckoutListing>
					</v-tab-item>
					<v-tab-item value="file">
						<Files
							v-if="projectTab == 'file'"
							class="mx-4"
							type-text="Project Files"
							type="project"
							:type-uuid="$route.params.uuid"
						>
						</Files>
					</v-tab-item>
					<v-tab-item value="event">
						<EventListing
							v-if="projectTab == 'event'"
							class="mx-4"
							type-text="Customer"
							type="project"
							:type-uuid="uuid"
						></EventListing>
					</v-tab-item>
				</v-tabs-items>
			</div>
			<DeleteTemplate
				type="Customer"
				:delete-text="deleteText"
				v-on:success="goBack()"
				v-on:close="deleteDialog = false"
				:delete-dialog="deleteDialog"
				:delete-url="deleteURL"
				:delete-note="
					project.activated
						? 'To preserve associated records, you could de-activate the Customer instead.'
						: null
				"
			>
				<template v-if="project.activated" v-slot:extra-btn>
					<v-btn
						class="white--text"
						depressed
						color="blue darken-4"
						tile
						v-on:click="
							deleteDialog = false;
							deactivateDialog = true;
						"
					>
						De-activate
					</v-btn>
				</template>
			</DeleteTemplate>
			<DeactivateTemplate
				type="Customer"
				:deactivate-text="deactivateText"
				v-on:success="goBack()"
				v-on:close="deactivateDialog = false"
				:deactivate-dialog="deactivateDialog"
				:deactivate-url="deactivateURL"
			>
			</DeactivateTemplate>
			<ActivateTemplate
				type="Customer"
				:activate-text="activateText"
				v-on:success="goBack()"
				v-on:close="activateDialog = false"
				:activate-dialog="activateDialog"
				:activate-url="activateURL"
			>
			</ActivateTemplate>
			<Dialog :dialog="startProjectDialog" :dialog-width="640">
				<template v-slot:title> Start Project</template>
				<template v-slot:body>
					<v-row class="delete-dialog">
						<v-col md="2" class="py-0 text-right my-auto">
							<span class="svg-icon svg-icon-lg delete-confirm-icon">
								<!--begin::Svg Icon-->
								<inline-svg :src="$assetURL('media/custom-svg/attention-circle.svg')" />
								<!--end::Svg Icon-->
							</span>
						</v-col>
						<v-col md="10" class="py-0 my-auto">
							<p class="btx-p m-0">
								Are you sure you want to Start
								<span class="red--text font-bolder lighten-1">{{ property && property.name }}</span> Project
								?
							</p>
						</v-col>
					</v-row>
				</template>
				<template v-slot:action>
					<v-btn
						class="white--text"
						:loading="pageLoading"
						:disabled="pageLoading"
						depressed
						color="green lighten-1"
						tile
						v-on:click="startProject"
					>
						Yes! Start
					</v-btn>
					<v-btn depressed tile :disabled="pageLoading" v-on:click="startProjectDialog = false">
						No, Close
					</v-btn>
				</template>
			</Dialog>
			<Dialog :dialog="completeProjectDialog" :dialog-width="640">
				<template v-slot:title> Complete Project</template>
				<template v-slot:body>
					<v-row class="delete-dialog">
						<v-col md="2" class="py-0 text-right my-auto">
							<span class="svg-icon svg-icon-lg delete-confirm-icon">
								<!--begin::Svg Icon-->
								<inline-svg :src="$assetURL('media/custom-svg/attention-circle.svg')" />
								<!--end::Svg Icon-->
							</span>
						</v-col>
						<v-col md="10" class="py-0 my-auto">
							<p class="btx-p m-0">
								Are you sure you want to Complete
								<span class="red--text font-bolder lighten-1">{{ property && property.name }}</span> Project
								?
							</p>
						</v-col>
					</v-row>
				</template>
				<template v-slot:action>
					<v-btn
						class="white--text"
						:loading="pageLoading"
						:disabled="pageLoading"
						depressed
						color="green lighten-1"
						tile
						v-on:click="completeProject"
					>
						Yes! Start
					</v-btn>
					<v-btn depressed tile :disabled="pageLoading" v-on:click="completeProjectDialog = false">
						No, Close
					</v-btn>
				</template>
			</Dialog>
		</template>
		<template v-else>
			<div class="mx-20 my-40 text-center">
				<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
			</div>
		</template>
	</v-sheet>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GetProject, StartProject, CompleteProject } from "@/core/lib/project.lib";
import ShowValue from "@/view/components/ShowValue";
import ShowPrice from "@/view/components/ShowPrice";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import { isEmpty } from "lodash";
import ActivateTemplate from "@/view/components/ActivateTemplate";
import DeactivateTemplate from "@/view/components/DeactivateTemplate";
import EventListing from "@/view/components/EventListing";
import CheckoutListing from "@/view/components/CheckoutListing";
import TransactionListing from "@/view/components/TransactionListing";
import Dialog from "@/view/components/Dialog.vue";
import Chip from "@/view/components/Chip";
import ImageTemplate from "@/view/components/Image";
import Files from "@/view/components/Files";
import TasksListing from "@/view/components/TasksListing";
import MilestoneListing from "@/view/components/MilestoneListing.vue";
import QuotationListing from "@/view/components/QuotationListing.vue";
import BudgetListing from "@/view/components/BudgetListing";
import ProformaListing from "@/view/components/ProformaListing.vue";
import InvoiceListing from "@/view/components/InvoiceListing";
import ProjectLineItems from "@/view/components/ProjectLineItems";
import PaymentListing from "@/view/components/PaymentListing";
import TeamMemberListing from "@/view/components/TeamMemberListing";
import ShowTextAreaValue from "@/view/components/ShowTextAreaValue";
export default {
	name: "project-detail",
	title: "Detail Detail",
	data() {
		return {
			uuid: null,
			barcode: null,
			customer: null,
			purchase_order: null,
			property: {},
			activateText: null,
			activateURL: null,
			deactivateText: null,
			deactivateURL: null,
			deleteText: null,
			contact_person: [],
			deleteURL: null,
			startProjectDialog: false,
			completeProjectDialog: false,
			formValid: true,
			personModel: {},
			deleteDialog: false,
			addressDialog: false,
			deactivateDialog: false,
			activateDialog: false,
			contentLoaded: true,
			pageLoading: true,
			// projectTab: "detail",
			project: {
				customer: null,
				purchase_order: null,
				name: null,
				quote: null,
				reference_no: null,
				description: null,
				start_date_formatted: null,
				end_date_formatted: null,
				remark: null,
				banker_guarantee_amount: null,
				banker_guarantee_remark: null,
			},
			groupSeries: [
				{
					name: "Budget Alloted",
					data: ["44 k", "55 K", "41 k", 64, 22],
				},
				{
					name: "Spend Budget",
					data: [32, 32, 33, 52, 13],
				},
			],
			series: [25, 10, 10],
			chartOptions: {
				plotOptions: {
					pie: {
						dataLabels: {
							offset: -10,
						},
						formatter: function (val) {
							return val + "%";
						},
					},
				},
				legend: {
					formatter: function (val) {
						if (val === "completed") {
							return val + ": " + 25;
						} else if (val === "Pending") {
							return val + ": " + 10;
						} else if (val === "In Progress") {
							return val + ": " + 10;
						}
					},
				},
				chart: {
					width: 380,
					type: "pie",
				},
				colors: ["#F44336", "#4CAF50", "#2196F3"],
				labels: ["completed", "Pending", "In Progress"],
				responsive: [
					{
						breakpoint: 480,
						options: {
							chart: {
								width: 200,
							},
							legend: {
								position: "bottom",
							},
						},
					},
				],
			},
			barChartOptions: {
				chart: {
					type: "bar",
					height: 430,
				},
				plotOptions: {
					bar: {
						horizontal: false,
						dataLabels: {
							position: "top",
						},
					},
				},
				dataLabels: {
					enabled: true,
					offsetY: 10,
					formatter: function (val) {
						return val + "K";
					},
					style: {
						fontSize: "12px",
						colors: ["#fff"],
					},
				},
				stroke: {
					show: true,
					width: 1,
					colors: ["#fff"],
				},
				yaxis: {
					title: {
						text: "Budget in thousand",
					},
				},
				tooltip: {
					y: {
						formatter: function (val) {
							return +val + "k";
						},
					},
				},
				xaxis: {
					categories: ["Budget 1", "Budget 2", "Budget 3", "Budget 4", "Budget 5"],
				},
			},
		};
	},
	methods: {
		doAction(param) {
			switch (param) {
				case "edit":
					this.$router.push({
						name: "project-update",
						params: { uuid: this.uuid },
						query: { t: new Date().getTime(), tab: "overview" },
					});
					break;
				case "delete":
					this.deleteDialog = true;
					break;
				case "deactivate":
					this.deactivateDialog = true;
					break;
				case "activate":
					this.activateDialog = true;
					break;
				case "new-address":
					this.addressDialog = true;
					break;
				case "print-label":
					break;
			}
		},
		startProject() {
			StartProject(this.uuid)
				.then(() => {
					this.getProject();
					this.startProjectDialog = false;
				})
				.catch((error) => {
					this.goBack();
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
					this.contentLoaded = true;
				});
		},
		completeProject() {
			CompleteProject(this.uuid)
				.then(() => {
					this.getProject();
					this.completeProjectDialog = false;
				})
				.catch((error) => {
					this.goBack();
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
					this.contentLoaded = true;
				});
		},
		getProject() {
			GetProject(this.uuid)
				.then((data) => {
					// this.barcode = data[0].barcode;
					this.project = data[0];
					this.customer = this.project.customers[0];
					// this.purchase_order = this.project.purchase_order[0];
					this.property = data.property;
					this.deleteText = `#${data.barcode}`;
					this.deleteURL = `project/${data.uuid}`;
					// this.activateText = `${data.barcode}`;
					this.activateURL = `project/${data.uuid}/activate`;
					// this.deactivateText = `${data.barcode}`;
					this.deactivateURL = `project/${data.uuid}/de-activate`;
					this.$store.dispatch(SET_BREADCRUMB, [
						{ text: "Project", disabled: true },
						{ text: "Detail", disabled: true },
						// { text: this.barcode, disabled: true },
					]);
				})
				.catch((error) => {
					// this.goBack();
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
					this.contentLoaded = true;
				});
		},
		addPerson(person) {
			this.personDialog = true;
			this.personModel = new Object();
			if (person && !isEmpty(person)) {
				this.personModel = new Object({
					id: person.id || null,
					uuid: person.uuid || null,
					salutation: person.salutation || null,
					first_name: person.first_name || null,
					last_name: person.last_name || null,
					email: person.email || null,
					mobile: person.phone || null,
					display_name: person.display_name || null,
					designation: person.designation || null,
					primary: person.primary || null,
				});
			}
		},
	},
	computed: {
		projectTab: {
			set(val) {
				let query = { ...this.$route.query };
				query.tab = val;
				if (val != this.projectTab) {
					this.$router.replace({ query });
				}
			},
			get() {
				return this.$route.query.tab || "detail";
			},
		},
	},
	components: {
		Files,
		ShowValue,
		ShowPrice,
		ShowTextAreaValue,
		BudgetListing,
		EventListing,
		DeleteTemplate,
		ActivateTemplate,
		ImageTemplate,
		Dialog,
		Chip,
		CheckoutListing,
		DeactivateTemplate,
		TransactionListing,
		TasksListing,
		MilestoneListing,
		ProformaListing,
		InvoiceListing,
		PaymentListing,
		TeamMemberListing,
		QuotationListing,
		ProjectLineItems,
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Contacts", disabled: true },
			{ text: "purchase_order", disabled: true },
			{ text: "Detail", disabled: true },
		]);

		const { uuid } = this.$route.params;
		this.uuid = uuid;
		if (!this.uuid) {
			this.goBack();
		}
		this.getProject();
	},
	beforeDestroy() {
		this.$store.dispatch(SET_BREADCRUMB, []);
	},
};
</script>
