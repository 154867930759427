import ApiService from "@/core/services/api.service";

export const GetProject = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`project/${uuid}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const getQuotationAndPurchaseOrder = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`project/get-option/listing?id=${uuid}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const CreateProject = (params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post("project", params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const UpdateProject = (uuid, params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.put(`project/${uuid}`, { ...params, uuid })
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const DeleteProject = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.delete(`project/${uuid}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const StartProject = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post(`project/${uuid}/start`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const CompleteProject = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post(`project/${uuid}/complete`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const ProjectRelated = (uuid, type) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`project/${uuid}/${type}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

// task
export const CreateTask = (params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post("tasks", params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const updateTask = (uuid, params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.put(`tasks/${uuid}`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const getTaskOfProject = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`project/${uuid}/option/task`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const deleteTasks = (params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.patch(`tasks`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const updateTaskStatus = (status, uuid) => {
	const params = {
		action: status,
		selected: [uuid],
	};
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.patch(`status/tasks`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const updateTaskPriorityStatus = (status, uuid) => {
	const params = {
		action: status,
		selected: [uuid],
	};
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.patch(`priority/tasks`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
// Sub Task
export const getSubTaskOfTask = (id) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`task/${id}/subtasks`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const CreateSubTask = (params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post(`subtasks`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const updateSubTask = (uuid, params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.put(`subtasks/${uuid}`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const deleteSubTask = (params) => {
	const subtasks = {
		subtasks: [params],
	};
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.patch(`subtasks`, subtasks)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
// Milestone
export const getMilestoneByUUID = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`project-milestone/${uuid}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const getMilestoneByProject = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`project/${uuid}/option/milestone`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const CreateMilestone = (params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post("project-milestone", params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const updateMilestone = (uuid, params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.put(`project-milestone/${uuid}/`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const deleteMilestone = (params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.patch("project-milestone", params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const updateMilestoneStatus = (params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.patch(`status/project-milestone`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
// Proforma
export const getProforma = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`performa-invoice/${uuid}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const getProformaByProject = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`project/${uuid}/option/proforma`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const CreateProforma = (params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post(`performa-invoice/project-milestone`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const updateProforma = (uuid, params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.put(`performa-invoice/${uuid}`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
// Budget
export const getBudgetByProject = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`project/${uuid}/option/budget`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const createBudget = (projectId, budgets) => {
	const params = {
		project: projectId,
		budgets: budgets,
	};
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post(`budget/project`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const deleteBudget = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.patch(`budget/project/${uuid}/delete`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const deleteSubBudget = (subBudgetUUID, budgetUUI) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.patch(`subbudget/${subBudgetUUID}/budget/${budgetUUI}/delete`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
// invoice
export const getInvoiceByProject = (uuid, data) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.query(`project/${uuid}/option/invoice`, data)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const getInvoiceByProjectMilestone = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`project-milestone/${uuid}/option/invoice`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const createInvoice = (params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post(`invoice`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const deleteInvoice = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.patch(`invoice/${uuid}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const getProformaDropDown = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`project-milestone/${uuid}/option/proforma-dropdown`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const getInvoiceDropDown = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`project-milestone/${uuid}/option/invoice-dropdown`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

// payment
export const getPaymentByProject = (uuid, params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.query(`project/${uuid}/option/payment`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const getPaymentByProjectMilestone = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`project-milestone/${uuid}/option/payments`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const createPayment = (params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post(`payment/project`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const deletePayment = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.patch(`payment/project/${uuid}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
// team member
export const getTeamMemberByProject = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`project/${uuid}/option/team`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const manageTeamMember = (projectuuid, params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post(`project/${projectuuid}/team-member/add`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
// quotation
export const getQuotationByProject = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`project/${uuid}/option/quotation`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const deleteQuotation = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.patch(`quotation/${uuid}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

// PROJECT DELIVERABLE
export const projectDeliverable = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`project/${uuid}/option/deliverable-summary`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
