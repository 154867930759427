<template>
	<div>
		<v-layout class="min-height-57px">
			<v-flex class="font-level-3-bold my-auto d-flex justify-space-between">
				<div class="d-flex align-center">
					<div class="detail-svg-icon d-flex align-center">
						<!--begin::Svg Icon-->
						<inline-svg
							fill="#006fc8"
							:src="$assetURL('media/custom-svg/budget.svg')"
							height="20"
							width="20"
							class="mr-2"
						/>
						<!--end::Svg Icon-->

						<template> Budget </template>
					</div>
					<div class="ml-6 d-flex">
						<div>
							<v-chip class="px-2 pr-2 py-2" color="orange" label outlined
								>Total Budget:
								<v-icon small right style="margin-right: 0.5px"> mdi-currency-usd </v-icon>
								{{ project.cost }}
							</v-chip>
						</div>
						<div class="mx-6">
							<v-chip class="px-2 pr-2 py-2" color="blue darken-4" label outlined>
								Total Remaining Budget:
								<v-icon small right style="margin-right: 0.5px"> mdi-currency-usd </v-icon
								><span v-if="loadedBudget.length">{{ project_remanding }}</span
								><span v-else>{{ project.cost }}</span></v-chip
							>
						</div>
						<div class="">
							<v-chip class="px-2 pr-2 py-2" color="green" label outlined>
								Total Budget Spend:
								<v-icon small right style="margin-right: 0.5px"> mdi-currency-usd </v-icon
								><span>{{ budget_spend }}</span></v-chip
							>
						</div>
					</div>
				</div>
				<div class="d-flex">
					<v-btn @click.stop.prevent="addBudgetRow" color="blue darken-4 white--text" tile>
						<v-icon>mdi-plus</v-icon> Add Budget</v-btn
					>
					<v-btn
						v-if="loadedBudget.length > 0"
						:loading="pageLoading"
						@click.stop.prevent="saveBudget()"
						color="blue darken-4 white--text"
						class="ml-4"
						tile
					>
						Save Budgets</v-btn
					>
				</div>
			</v-flex>
		</v-layout>
		<div
			v-if="!pageLoading"
			class="overflow-y"
			style="max-height: calc(100vh - 337px); min-height: 200px"
		>
			<v-form v-model.trim="formValid" ref="budgetForm" lazy-validation class="budget-listing">
				<v-simple-table class="bt-table listing-table table-head-sticky budget1">
					<thead>
						<tr>
							<th width="40px">#</th>
							<th>Name</th>
							<th>Cost</th>
							<th width="100px">Action</th>
						</tr>
					</thead>
					<tbody v-if="loadedBudget.length">
						<template v-for="(row, index) in loadedBudget">
							<tr :key="index + 1" class="cursor-pointer">
								<td style="vertical-align: top" class="py-3">{{ index + 1 }}</td>
								<td class="pb-3">
									<TextInput
										hide-details
										:rules="[vrules.required(row.name, 'Budget Name')]"
										:disabled="pageLoading"
										:loading="pageLoading"
										id="display-name"
										placeholder="Name"
										v-model="row.name"
										:class="{ required: !row.name }"
									></TextInput>

									<table width="100%" class="sub-budget">
										<tr
											v-for="(subBudget, subBudgetIndex) in row.sub_budgets"
											:key="subBudgetIndex + 4"
											class="cursor-pointer ml-auto"
											style="min-width: 100%"
										>
											<td>
												<TextInput
													:rules="[vrules.required(subBudget.name, 'Sub Budget Name')]"
													hide-details
													:disabled="pageLoading"
													:loading="pageLoading"
													:class="{ required: !subBudget.name }"
													id="sub-budgets"
													placeholder="Name"
													style="min-width: 100%"
													v-model="subBudget.name"
												>
												</TextInput>
											</td>
										</tr>
									</table>
								</td>
								<td class="pb-3">
									<PriceInput
										hide-details
										:disabled="pageLoading"
										:loading="pageLoading"
										id="Cost"
										type="number"
										placeholder="Cost"
										v-model.number="row.cost"
										@change="budgetPriceChange"
										@keydown="onkeydown($event, index)"
										@keypress="budgetChange($event, index)"
										@paste="budgetPaste($event, index)"
									>
									</PriceInput>
									<table width="100%" class="sub-budget">
										<tr
											v-for="(subBudget, subBudgetIndex) in row.sub_budgets"
											:key="subBudgetIndex + 4"
											class="cursor-pointer"
											style="min-width: 100%"
										>
											<td class="d-flex align-center">
												<PriceInput
													hide-details
													:disabled="pageLoading"
													type="number"
													:loading="pageLoading"
													id="cost"
													placeholder="Cost"
													v-model.number="subBudget.cost"
													@paste="subBudgetPaste($event, index, subBudgetIndex, row)"
													@keypress="subBudgetsChange($event, row, index, subBudgetIndex)"
												>
													<!-- <PriceInput hide-details :disabled="pageLoading" type="number"
													:loading="pageLoading" id="cost" placeholder="Cost"
													v-model.number="subBudget.cost"> -->
												</PriceInput>
												<v-tooltip top>
													<template v-slot:activator="{ on, attrs }">
														<span v-bind="attrs" v-on="on">
															<v-btn
																@click="deleteSubBudget(index, row, subBudgetIndex, subBudget)"
																class="red white--text ml-4"
																fab
																x-small
															>
																<v-icon color="white" x-small>mdi-delete</v-icon>
															</v-btn>
														</span>
													</template>
													<span>Delete Sub Budget</span>
												</v-tooltip>
											</td>
										</tr>
									</table>
								</td>

								<td class="py-3" style="vertical-align: top">
									<v-tooltip top>
										<template v-slot:activator="{ on, attrs }">
											<span v-bind="attrs" v-on="on">
												<v-btn
													@click.prevent.stop="createSubBudget(index, false)"
													color="blue darken-4 white--text"
													dark
													fab
													x-small
												>
													<v-icon>mdi-plus</v-icon></v-btn
												>
											</span>
										</template>
										<span>Create Sub Budget</span>
									</v-tooltip>
									<v-tooltip top>
										<template v-slot:activator="{ on, attrs }">
											<span v-bind="attrs" v-on="on">
												<v-btn @click="deleteBudget(index, row)" class="red white--text ml-4" fab x-small>
													<v-icon color="white">mdi-delete</v-icon>
												</v-btn>
											</span>
										</template>
										<span>Delete Budget</span>
									</v-tooltip>
								</td>
							</tr>
						</template>
					</tbody>
					<tfoot v-else>
						<tr>
							<td colspan="11">
								<p class="m-0 row-not-found text-center py-3">
									<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
									Uhh... There are no budget at the moment.
								</p>
							</td>
						</tr>
					</tfoot>
				</v-simple-table>
			</v-form>
		</div>
		<div v-else class="mx-20 my-40 text-center">
			<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
		</div>
		<Dialog :dialog="deleteDialog" :dialog-width="640">
			<template v-slot:title> Delete Budget</template>
			<template v-slot:body>
				<v-row class="delete-dialog">
					<v-col md="2" class="py-0 text-right my-auto">
						<span class="svg-icon svg-icon-lg delete-confirm-icon">
							<!--begin::Svg Icon-->
							<inline-svg :src="$assetURL('media/custom-svg/attention-circle.svg')" />
							<!--end::Svg Icon-->
						</span>
					</v-col>
					<v-col md="10" class="py-0 my-auto">
						<p class="btx-p m-0">
							<span class="text-capitalize">Budget</span>
							<span class="red--text font-bolder lighten-1"> # {{ uuid }} </span>
							will be deleted forever and cannot be retrieved later. Are you sure about deleting it?
						</p>
					</v-col>
					<!-- <v-col v-if="deleteNote" md="12" class="pb-0 mt-2 my-auto">
						<em>Note: {{ deleteNote }}</em>
					</v-col> -->
				</v-row>
			</template>
			<template v-slot:action>
				<v-btn class="white--text" depressed color="red lighten-1" tile> Yes! Delete </v-btn>
				<v-btn depressed tile @click="deleteDialog = false"> No, Close </v-btn>
			</template>
		</Dialog>
		<DeleteTemplate
			type="Budget"
			v-on:success="deleteConfirmBudget"
			:deleteByUrl="false"
			v-on:close="deleteBudgetDialog = false"
			:delete-dialog="deleteBudgetDialog"
			:deleteText="budgetUUID"
		>
		</DeleteTemplate>
		<DeleteTemplate
			type="Sub Budget"
			v-on:success="deleteConfirmSubBudget"
			:deleteByUrl="false"
			v-on:close="deleteSubBudgetDialog = false"
			:delete-dialog="deleteSubBudgetDialog"
			:deleteText="subBudgetUUID"
		>
		</DeleteTemplate>
	</div>
</template>
<script>
import TextInput from "@/view/components/TextInput";
import PriceInput from "@/view/components/PriceInput";
import Dialog from "@/view/components/Dialog";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import {
	getBudgetByProject,
	createBudget,
	deleteBudget,
	deleteSubBudget,
} from "@/core/lib/project.lib";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import { mapGetters } from "vuex";
import { cloneDeep } from "lodash";

export default {
	name: "budget-listing",
	title: "Budget Listing",
	components: {
		TextInput,
		Dialog,
		DeleteTemplate,
		PriceInput,
	},
	data() {
		return {
			pageLoading: false,
			// budgetSNO: null,
			// project_budget: 3000,
			project_remanding: 0,
			uuid: null,
			subBudgetUUID: null,
			budgetUUID: null,
			// isApproveDialogOpen: false,
			// isMilestoneDetailDialogOpen: false,
			// isCreateMilestoneDialog: false,
			formValid: true,
			budgets: [],
			deleteDialog: false,
			deleteSubBudgetDialog: false,
			deleteBudgetDialog: false,
			budget: {
				name: null,
				cost: null,
			},
			loadedBudget: [],
			budget_spend: null,
		};
	},
	props: {
		project: {
			type: Object,
			default: () => {},
		},
	},
	methods: {
		addBudgetRow() {
			const budget = {
				name: null,
				cost: 0,
				// editable: true,
				sub_budgets: [],
			};
			this.loadedBudget = [...this.loadedBudget, budget];
		},
		createSubBudget(index) {
			const sub_budgets = {
				name: null,
				cost: 0,
				// editable: true,
			};
			if (this.loadedBudget[index].sub_budgets) {
				this.loadedBudget[index].sub_budgets = [...this.loadedBudget[index].sub_budgets, sub_budgets];
			} else {
				this.loadedBudget[index].sub_budgets.push({
					name: null,
					cost: null,
				});
			}
		},

		openApproveConfirmationDialog(budget) {
			this.uuid = budget.uuid;
			this.deleteDialog = true;
		},
		deleteBudget(index, budget) {
			if (budget?.uuid) {
				this.deleteBudgetDialog = true;
				this.budgetUUID = budget.uuid;
			} else {
				this.loadedBudget.splice(index, 1);
			}
		},
		async deleteConfirmBudget() {
			const _this = this;
			_this.pageLoading = true;
			try {
				await deleteBudget(_this.budgetUUID);
				_this.$store.commit(SET_MESSAGE, [
					{ model: true, message: `Success !Budget has been deleted.` },
				]);
				_this.budgetUUID = null;
				_this.deleteBudgetDialog = false;
				_this.initBudgets();
			} catch (error) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				_this.pageLoading = false;
			}
		},
		async deleteConfirmSubBudget() {
			const _this = this;
			_this.pageLoading = true;
			try {
				await deleteSubBudget(_this.subBudgetUUID, _this.budgetUUID);
				_this.$store.commit(SET_MESSAGE, [
					{ model: true, message: `Success ! Sub Budget has been deleted.` },
				]);
				_this.subBudgetUUID = null;
				_this.budgetUUID = null;
				_this.initBudgets();
				_this.deleteSubBudgetDialog = false;
			} catch (error) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				_this.pageLoading = false;
			}
		},
		async deleteSubBudget(budgetIndex, budget, subBudgetIndex, subBudget) {
			const _this = this;
			if (subBudget?.uuid) {
				_this.deleteSubBudgetDialog = true;
				_this.subBudgetUUID = subBudget.uuid;
				_this.budgetUUID = budget.uuid;
			} else {
				_this.loadedBudget[budgetIndex]["sub_budgets"].splice(subBudgetIndex, 1);
			}
		},
		async saveBudget() {
			const _this = this;
			const formErrors = _this.validateForm(_this.$refs.budgetForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}
			if (!_this.$refs.budgetForm.validate()) {
				return false;
			}

			this.pageLoading = true;
			try {
				await createBudget(this.project.id, this.loadedBudget);
				this.initBudgets();
				await this.initBudgets();
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				this.pageLoading = false;
			}
		},
		async initBudgets() {
			try {
				this.pageLoading = true;
				const data = await getBudgetByProject(this.$route.params.uuid);
				this.loadedBudget = data.budgets;
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				this.pageLoading = false;
			}
		},
		budgetPriceChange() {
			this.remainingProjectBudget();
		},
		remainingProjectBudget() {
			this.project_remanding =
				this.project.cost -
				this.loadedBudget.reduce((total, ele) => {
					return total + ele.cost;
				}, 0);
		},
		totalBudgetSpend() {
			if (this.loadedBudget) {
				this.budget_spend = this.loadedBudget.reduce((total, ele) => {
					return total + ele.cost;
				}, 0);
			} else {
				this.budget_spend = 0;
			}
		},
		subBudgetsChange($event, row, budget_index, subBudgetIndex) {
			let subBudgets = cloneDeep(this.loadedBudget[budget_index].sub_budgets);
			let currentSubBudget = Number(String(subBudgets[subBudgetIndex].cost) + String($event.key));
			subBudgets[subBudgetIndex].cost = currentSubBudget;
			let totalSubBudgetCost = 0;
			subBudgets.forEach((ele) => {
				totalSubBudgetCost = ele.cost + totalSubBudgetCost;
			});
			if (totalSubBudgetCost > row.cost) {
				$event.preventDefault();
				this.$store.commit(SET_ERROR, [
					{ model: true, message: "Total Sub Budget Value can not be greater then budget value" },
				]);
			}
		},
		subBudgetPaste($event, budget_index, subBudgetIndex, row) {
			let clipboardData, pastedData;
			clipboardData = $event.clipboardData || window.clipboardData;
			pastedData = clipboardData.getData("text/plain");
			let subBudgets = cloneDeep(this.loadedBudget[budget_index].sub_budgets);
			let currentSubBudget = Number(String(subBudgets[subBudgetIndex].cost) + String(pastedData));
			subBudgets[subBudgetIndex].cost = currentSubBudget;
			let totalSubBudgetCost = 0;
			subBudgets.forEach((ele) => {
				totalSubBudgetCost = ele.cost + totalSubBudgetCost;
			});
			if (totalSubBudgetCost > row.cost) {
				$event.stopPropagation();
				$event.preventDefault();
				this.$store.commit(SET_ERROR, [
					{ model: true, message: "Total Sub Budget Value can not be greater then budget value" },
				]);
				setTimeout(() => {
					this.loadedBudget[budget_index].sub_budgets[subBudgetIndex].cost = 0;
				}, 100);
			}
		},
		budgetPaste($event, index) {
			let clipboardData, pastedData;
			clipboardData = $event.clipboardData || window.clipboardData;
			pastedData = clipboardData.getData("text/plain");
			let budget = cloneDeep(this.loadedBudget[index]);
			let budgets = cloneDeep(this.loadedBudget);
			this.loadedBudget[index].sub_budgets.forEach((subBudget) => {
				subBudget.cost = null;
			});
			let totalBudget1 = Number(String(budget.cost) + String(pastedData));
			budgets[index].cost = totalBudget1;
			let totalBudgetCost = 0;
			budgets.forEach((ele) => {
				totalBudgetCost = ele.cost + totalBudgetCost;
			});
			if (totalBudgetCost > this.project.cost) {
				$event.stopPropagation();
				$event.preventDefault();
				setTimeout(() => {
					this.loadedBudget[index].cost = 0;
				}, 100);
				this.$store.commit(SET_ERROR, [
					{ model: true, message: "Budgets Value can not be greater then project budget value" },
				]);
			}
		},
		onkeydown($event, index) {
			if ($event.keyCode === 8) {
				this.loadedBudget[index].sub_budgets.forEach((subBudget) => {
					subBudget.cost = null;
				});
			}
		},

		budgetChange($event, index) {
			let budget = cloneDeep(this.loadedBudget[index]);
			let budgets = cloneDeep(this.loadedBudget);
			let totalBudget1 = Number(String(budget.cost) + String($event.key));
			budgets[index].cost = totalBudget1;
			let totalBudgetCost = 0;
			budgets.forEach((ele) => {
				totalBudgetCost = ele.cost + totalBudgetCost;
			});
			if (totalBudgetCost > this.project.cost) {
				$event.preventDefault();

				this.$store.commit(SET_ERROR, [
					{ model: true, message: "Budgets Value can not be greater then project budget value" },
				]);
				setTimeout(() => {
					this.loadedBudget[index].cost = 0;
				}, 100);
			}
		},
	},
	watch: {
		loadedBudget: {
			handler() {
				this.remainingProjectBudget();
				this.totalBudgetSpend();
			},
			deep: true,
		},
	},
	computed: {
		...mapGetters(["errors"]),
	},
	async mounted() {
		await this.initBudgets();
		this.remainingProjectBudget();
		this.totalBudgetSpend();
	},
};
</script>
<style scoped>
.cursor-not-allowed {
	cursor: not-allowed;
}
</style>
