<template>
	<div>
		<v-layout class="p-4 border-bottom-light-grey min-height-57px">
			<v-flex class="font-level-3-bold my-auto d-flex justify-end">
				<v-btn @click="addSubtask" class="mx-4" color="blue darken-4 white--text" tile depressed>
					<!-- <v-icon>mdi-plus</v-icon>  -->Save</v-btn
				>
				<v-btn @click="openAddDialog" color="blue darken-4 white--text" tile depressed>
					<v-icon>mdi-plus</v-icon> Add Sub Task</v-btn
				>
			</v-flex>
		</v-layout>
		<v-col md="12" class="bt-table listing-table">
			<!-- <v-form v-if="!pageLoading" ref="subTaskForm" lazy-validation> -->
			<table v-if="!pageLoading" width="100%">
				<thead>
					<tr>
						<th max-width="150px" class="simple-table-th">#</th>
						<th max-width="150px" class="simple-table-th">Name</th>
						<!-- <th max-width="150px" class="simple-table-th"></th> -->
						<th max-width="150px" class="simple-table-th">Description</th>
						<th max-width="150px" class="simple-table-th">Remark</th>
						<th max-width="150px" class="simple-table-th">Actions</th>
					</tr>
				</thead>

				<tbody v-if="subTaskListing">
					<tr
						v-clickoutside="outsideClicked"
						class="task_row"
						v-for="(row, index) in subTaskListing"
						:key="index"
					>
						<td width="10px" class="td_column cursor-default simple-table-td">{{ index + 1 }}</td>
						<!-- <td>{{ row.isEditable }}</td> -->
						<td
							:class="row.isEditable ? 'px-2' : 'px-3'"
							width="250px"
							max-height="42px"
							@click="editRow($event, index)"
							class="td_column cursor-default simple-table-td text-capitalize"
						>
							<!-- {{ subTaskListing[index].isEditable }} -->
							<TextInput
								v-if="row.isEditable"
								dense
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								id="name"
								placeholder="Name"
								v-model="row.name"
								change="changeClicked()"
								:class="{
									required: !row.name,
								}"
							>
							</TextInput>
							<span v-else>{{ row.name }}</span>
						</td>
						<td
							@click="editRow($event, index)"
							width="250px"
							max-height="42px"
							class="td_column cursor-default simple-table-td"
							:class="row.isEditable ? 'px-2' : 'px-3'"
						>
							<TextInput
								dense
								v-if="row.isEditable"
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								id="description"
								placeholder="Description"
								v-model="row.description"
							>
							</TextInput>
							<template v-else>
								<span class="line-clamp-2" style="max-width: 200px">
									<ShowTextAreaValue
										v-if="row.description"
										:object="row"
										object-key="description"
										label="Description"
									>
									</ShowTextAreaValue>
									<span v-else>{{ row.description }}</span>
								</span>
							</template>
						</td>
						<td
							@click="editRow($event, index)"
							width="250px"
							max-height="42px"
							class="td_column cursor-default simple-table-td"
							:class="row.isEditable ? 'px-2' : 'px-3'"
						>
							<!-- <ShowValue :object="row" object-key="remark" label="Remark"></ShowValue> -->
							<TextInput
								dense
								v-on:keydown.tab="tabClickedQty(index)"
								v-if="row.isEditable"
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								id="description"
								placeholder="Remark"
								v-model="row.remark"
							>
							</TextInput>
							<template v-else
								><!-- {{ row.remark }} -->
								<span class="line-clamp-2" style="max-width: 200px">
									<ShowTextAreaValue v-if="row.remark" :object="row" object-key="remark" label="remark">
									</ShowTextAreaValue>
									<span v-else>{{ row.remark }}</span>
								</span>
							</template>
						</td>

						<td class="simple-table-td d-flex justify-end px-3" max-height="42px">
							<v-btn
								class="mx-2"
								color="red lighten-1"
								dark
								fab
								x-small
								@click="openDeleteConfirm(index, row)"
							>
								<v-icon small dark> mdi-trash-can-outline</v-icon>
							</v-btn>
							<!-- <v-btn class="mx-2" color="blue darken-4" dark fab x-small @click="editSubTask(row)">
								<v-icon small dark> mdi-pencil</v-icon>
							</v-btn> -->

							<!-- <v-btn color="blue darken-4 white--text" tile depressed> Add Sub Task </v-btn> -->
						</td>
					</tr>
				</tbody>
			</table>
			<!-- </v-form> -->
			<div v-else class="mx-20 text-center">
				<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
			</div>
		</v-col>

		<!-- Sub Budget Add Dialog -->
		<!-- <Dialog :dialog="addDialog" title="Sub Task" current v-on:close="addDialog = false">
			<template v-slot:title v-if="!uuid"> Add Sub Task </template>
			<template v-slot:title v-else>Update Sub Task </template>
			<template v-slot:body>
				<div class="min-height-280">
					<v-form v-if="addDialog" ref="subTaskForm" lazy-validation>
						<v-row>
							<v-col md="3" class="py-0">
								<label for="name" class="btx-label mt-5">Name</label>
							</v-col>
							<v-col md="9" class="py-0">
								<TextInput hide-details :disabled="pageLoading" :loading="pageLoading" id="name"
									placeholder="Name" v-model="subTask.name"
									:rules="[vrules.required(subTask.name, 'Name')]" :class="{
										required: !subTask.name,
									}">>
								</TextInput>
							</v-col>
							<v-col md="3" class="py-0">
								<label for="description" class="btx-label mt-5">Description</label>
							</v-col>
							<v-col md="9" class="py-0">
								<TextAreaInput hide-details :disabled="pageLoading" :loading="pageLoading" id="description"
									placeholder="Description" v-model="subTask.description">
								</TextAreaInput>
							</v-col>

							<v-col md="3" class="py-0">
								<label for="remark" class="btx-label mt-5">Remark</label>
							</v-col>
							<v-col md="9" class="py-0">
								<TextAreaInput hide-details :disabled="pageLoading" :loading="pageLoading" id="remark"
									placeholder="Remark" v-model="subTask.remark"></TextAreaInput>
							</v-col></v-row>
					</v-form>
				</div>
			</template>
			<template v-slot:action>
				<v-btn depressed tile :disabled="pageLoading" v-on:click="closeAddDialog"> Close </v-btn>
				<v-btn class="white--text" depressed color="blue darken-4" tile @click="addSubtask">
					Save
				</v-btn>
			</template>
		</Dialog> -->

		<DeleteTemplate
			type="Sub Task"
			:deleteText="`#${uuid}`"
			v-on:success="deleteSubTask"
			v-on:close="(isDeleteDialogOpen = false), (uuid = null)"
			:delete-dialog="isDeleteDialogOpen"
			:deleteByUrl="false"
		>
		</DeleteTemplate>
	</div>
</template>
<script>
// import Table from "@/view/components/Table";
import { mapGetters } from "vuex";
// import ShowValue from "@/view/components/ShowValue.vue";
import ListingMixin from "@/core/mixins/listing.mixin";
// import Dialog from "@/view/components/Dialog";
// import TextAreaInput from "@/view/components/TextAreaInput";
import TextInput from "@/view/components/TextInput";
import ShowTextAreaValue from "@/view/components/ShowTextAreaValue";
import "@/core/event-bus/click-outside";
import {
	getSubTaskOfTask,
	CreateSubTask,
	/* updateSubTask */
	deleteSubTask,
} from "@/core/lib/project.lib";
import { map, filter } from "lodash";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import DeleteTemplate from "@/view/components/DeleteTemplate";
export default {
	name: "sub-task",
	title: "Sub Task",
	mixins: [ListingMixin],
	components: {
		// Table,
		// Dialog,
		// ShowValue,
		ShowTextAreaValue,
		// TextAreaInput,
		TextInput,
		DeleteTemplate,
	},
	data() {
		return {
			// internal: true,
			project: null,
			uuid: null,
			// relation_type: null,
			// relation_type_id: 0,
			subTaskDialog: false,
			addDialog: false,
			isDeleteDialogOpen: false,
			isOutside: true,
			// subTaskListing: null,
			subTaskListing: [
				{
					subtask_uuid: null,
					isEditable: false,
					name: "Name 1",
					description: "Description 1",
					remark: "Remark",
				},
			],
			taskTab: null,
			subTask: {
				name: null,
				description: null,
				remark: null,
			},
		};
	},
	props: {
		// status: {
		// 	type: String,
		// 	default: null,
		// },
		task: {
			type: Object,
			default: () => {},
		},
	},
	methods: {
		// init() {
		// 	this.relation_type = this.relationType;
		// 	this.relation_type_id = this.relationTypeId;
		// 	if (this.type) {
		// 		this.defaultFilter.request_type = this.type;
		// 	}
		// 	if (this.status) {
		// 		this.defaultFilter.status = this.status;
		// 	}
		// },
		tabClickedQty(index) {
			this.tabClicked(index);
		},
		tabClicked(index) {
			if (this.subTaskListing.length == index + 1) {
				this.openAddDialog();
			}
			const _line_items = map(this.subTaskListing, (row) => {
				return { ...row, isEditable: false };
			});
			this.subTaskListing = _line_items;
			this.subTaskListing[index + 1].isEditable = true;
		},
		outsideClicked() {
			if (this.isOutside) {
				const _line_items = map(this.subTaskListing, (row) => {
					return { ...row, isEditable: false };
				});
				this.subTaskListing = _line_items;
				this.isOutside = false;
			}
		},
		// closeAddDialog() {
		// 	(this.addDialog = false),
		// 		this.subTask = {
		// 			name: null,
		// 			description: null,
		// 			remark: null,
		// 		};
		// },

		async openDeleteConfirm(index, row) {
			if (row.uuid) {
				const _this = this;
				// this.pageLoading = true;
				try {
					await deleteSubTask(row.uuid);
					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Task has been deleted." },
					]);
					await this.loadSubTask();
				} catch (error) {
					_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				} finally {
					_this.pageLoading = false;
				}
			} else {
				this.subTaskListing.splice(index, 1);
			}
			// this.isDeleteDialogOpen = true;
			// this.uuid = row.uuid;
		},
		// editSubTask(row) {
		// 	const _this = this;
		// 	_this.addDialog = true;
		// 	_this.uuid = row.uuid;
		// 	_this.subTask = {
		// 		name: row.name,
		// 		description: row.description,
		// 		remark: row.remark,
		// 	};
		// },
		openSubTaskDialog() {
			this.subTaskDialog = true;
		},
		openAddDialog() {
			this.subTaskListing.push({
				subtask_uuid: null,
				isEditable: false,
				name: null,
				description: null,
				remark: null,
			});
		},
		changeClicked() {
			this.isOutside = true;
		},
		async addSubtask() {
			const _this = this;
			// const formErrors = _this.validateForm(_this.$refs.subTaskForm);
			// if (formErrors.length) {
			// 	_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
			// 	return false;
			// }
			// if (!_this.$refs.subTaskForm.validate()) {
			// 	return false;
			// }
			// this.subTaskListing.forEach((ele) => {
			// 	if (!ele.name) {
			// 		_this.$store.commit(SET_ERROR, [{ model: true, message: "Name is required" }]);
			// 		return
			// 	}
			// })

			try {
				_this.pageLoading = true;
				// remove last row
				_this.subTaskListing.pop();
				let subTaskData = filter(this.subTaskListing, (row) => !row.name);
				if (subTaskData.length > 0) {
					_this.$store.commit(SET_ERROR, [{ model: true, message: "Name is required" }]);
					this.subTaskListing.push({
						subtask_uuid: null,
						isEditable: false,
						name: null,
						description: null,
						remark: null,
					});
					return false;
				}
				const formData = {
					task: _this.task.id,
					subtasks: this.subTaskListing,
				};
				await CreateSubTask(formData);
				_this.$store.commit(SET_MESSAGE, [
					{ model: true, message: "Success ! Task has been created." },
				]);

				await _this.loadSubTask();
				_this.$store.commit(SET_MESSAGE, [
					{ model: true, message: "Success ! Task has been deleted." },
				]);
			} catch (error) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				_this.pageLoading = false;
			}
		},
		editRow(event, index) {
			this.isOutside = false;
			let element = event.target;
			const _line_items = map(this.subTaskListing, (row) => {
				return { ...row, isEditable: false };
			});
			this.subTaskListing = _line_items;
			// this.line_items[index].isEditable =true;
			this.subTaskListing[index].isEditable = true;
			this.$nextTick(() => {
				const inputarea = element.querySelector("input");
				const textarea = element.querySelector("textarea");
				if (inputarea) {
					inputarea.focus();
					inputarea.select();
				}
				if (textarea) {
					textarea.focus();
					textarea.select();
				}
			});
			if (element.nodeName == "INPUT") {
				element.select();
			}
			if (element.nodeName == "TEXTAREA") {
				element.select();
			}
			if (this.subTaskListing.length == index + 1) {
				this.openAddDialog();
			}
		},
		// subTaskBlurOut(index) {
		// 	this.subTaskListing[index].isEditable = false

		// },
		async loadSubTask() {
			const _this = this;
			this.pageLoading = true;
			try {
				const { tbody } = await getSubTaskOfTask(this.task.id);
				_this.subTaskListing = tbody;
			} catch (error) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				_this.pageLoading = false;
			}
		},
		async deleteSubTask() {
			const _this = this;
			this.pageLoading = true;
			try {
				await deleteSubTask(this.uuid);
				_this.$store.commit(SET_MESSAGE, [
					{ model: true, message: "Success ! Task has been deleted." },
				]);
				await this.loadSubTask();
				this.isDeleteDialogOpen = false;
				_this.uuid = null;
			} catch (error) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				_this.pageLoading = false;
			}
		},
	},
	async mounted() {
		await this.loadSubTask();
		this.subTaskListing.push({
			subtask_uuid: null,
			isEditable: false,
			name: null,
			description: null,
			remark: null,
		});
		this.pageLoading = false;
	},
	computed: {
		...mapGetters(["errors"]),
	},
	// beforeMount() {
	// 	// this.init();
	// },
};
</script>
